import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'app/shared/shared.module';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone: true,
    imports     : [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule
    ]
})
export class UserComponent implements OnInit, OnDestroy
{
    static ngAcceptInputType_showAvatar: BooleanInput;
    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private _userService = inject(UserService)
    private _fuseMediaWatcherService = inject(FuseMediaWatcherService)
    private _navigationSrv = inject(NavigationService)
    private _fuseNavigationService = inject(FuseNavigationService)

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router
    ){}

    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((user: User) => {
            this.user = user;
            const clientType = user.company.client_type;
            const clientCategory = user.company.client_category;
            const clientId = user.company.client_id;
            const roles = user.roles;
            if(clientType === 'JODAX') {
                this._navigationSrv.getAdmin().subscribe();
            } else if (['SHIPPER'].includes(clientType)) {

                if(['SHIPPER'].includes(clientCategory)) {
                    if (roles.includes('sbrcargo_admin_access')) {
                        this._navigationSrv.getShipment().subscribe();
                        // Block upload fiagril
                        setTimeout(() => this._updateNavigation(clientId), 400);
                    } else {
                        this._navigationSrv.getViewerShipment().subscribe();
                    }
                } else if(['MINERADORA'].includes(clientCategory)) {
                    this._navigationSrv.getMiner().subscribe();
                }


            } else if (clientType === 'TRANSPORTADOR') {

                this._navigationSrv.getCarrier().subscribe();

            } else {

                this._router.navigate(['/sign-out']);
            }
            this._changeDetectorRef.markForCheck();
        });

        this._fuseMediaWatcherService.onMediaChange$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            // Check if the screen is small
            setTimeout(() => this._updateNavigation(this.user.company.client_id), 400);
        });
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }

    private _updateNavigation(clientId: string): void
    {
      const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
      if ( !navComponent ) {return null;}
      const navigation = navComponent.navigation;
      const managerMenu = this._fuseNavigationService.getItem('uploads', navigation);
      if (clientId === 'ebcdba17-c86d-400c-907b-56c8aa613d9c') {
        // FIAGRIL
        const idx = managerMenu.children.findIndex(el => el.id === 'nfe');
        if (idx > -1) {
            managerMenu.children.splice(idx, 1);
        }
      } else {
        if (managerMenu !== null && managerMenu !== undefined) {
            const idx = managerMenu.children.findIndex(el => el.id === 'sheetrt');
            if (idx > -1) {
                managerMenu.children.splice(idx, 1);
            }
        }
      }
      navComponent.refresh();
    }
}
